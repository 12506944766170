import type { IAidsDetailResponse } from '@/Interfaces'
import { request } from '@/utils/request'

const getDetail = async (id: number): Promise<IAidsDetailResponse> => {
  const response = await request(`aids/${id}/view`)
  return await response.json()
}

export default {
  getDetail,
}
