import { UTCDate } from '@date-fns/utc'
import type { IRadioItem } from './Interfaces'
import type { LoginSteps, UserTypes } from './Types'

export const CURRENT_YEAR = new UTCDate().getFullYear()

export const CURRENT_MONTH = new UTCDate().getMonth()

// The following array used for translations
export const WEEKDAYS_CODES = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export const LONG_MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

export const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Maj',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
]

export const API_SERVER = '/api'

export const IBG_API_URL = import.meta.env.VITE_GRAPHQL_API

export const UNTRANSLATED_YES_NO_RADIOS: IRadioItem[] = [
  {
    title: 'global.yes',
    value: true,
  },
  {
    title: 'global.no',
    value: false,
  },
]

export const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL
export const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_KEYCLOAK_CLIENT_ID
export const KEYCLOAK_IDP_HINT = import.meta.env.VITE_KEYCLOAK_IDP_HINT
export const KEYCLOAK_IDP_HINT_RELATIVE = import.meta.env
  .VITE_KEYCLOAK_IDP_HINT_RELATIVE
export const KEYCLOAK_REDIRECT_URI = `${window.location.origin}/callback`

/**
 * The maximum time for inactivity for a session. Inactivity means the user
 * doesn't do anything on the screen like scrolling, clicking, etc. After the
 * 15 minutes, we clear the session because of security concerns.
 */
export const SESSION_MAX_INACTIVITY = 15 // 15 minutes

/**
 * This value means that how many minutes before we should display a warning
 * that the session will be deactivate.
 */
export const SESSION_INACTIVITY_WARNING = 5 * 60 // 5 minutes

/**
 * The following HTTP status codes should be reported to the Sentry
 */
export const REPORTABLE_STATUSES_FOR_SENTRY = [
  400, 402, 403, 404, 405, 406, 407, 408, 409, 410, 413, 414, 415, 429, 502,
  503, 504,
]

/**
 * The maximum file upload size in MB
 */
export const MAX_FILE_UPLOAD_SIZE = 50

/**
 * Allowed file upload mime types.
 */
export const ALLOWED_MIME_TYPES: string[] = [
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/msword',
  'application/msword',
  'image/png',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/xml',
  'image/gif',
  'image/bmp',
  'application/vnd.ms-powerpoint',
  'text/plain',
  'application/vnd.ms-outlook',
  'image/jpg',
  'image/jpeg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

/**
 * If the current user is not found on Nexus, we should set the correct step
 * by the user type. This is important because we need to know all the time
 * that what kind of user type is trying to login.
 */
export const LOGIN_TOKEN_NOT_FOUND_MAP: Record<UserTypes, LoginSteps> = {
  citizen: 'citizen:user_not_found',
  parent: 'parent:user_not_found',
  relative: 'relative:user_not_found',
}

/**
 * If the MitID login is succeeded, what is the next step?
 *
 * For each user type we should start a different step.
 */
export const LOGIN_MIT_ID_SUCCESS_MAP: Partial<Record<UserTypes, LoginSteps>> =
  {
    citizen: 'citizen:logged',
    relative: 'relative:proxy_input',
    parent: 'parent:proxy_select',
  }

/**
 * What is the next step after the missing user created on NEXUS?
 *
 * This map is used to set the correct next step by the current step.
 */
export const LOGIN_NOT_FOUND_SUCCESS_MAP: Partial<
  Record<LoginSteps, LoginSteps>
> = {
  'citizen:user_not_found': 'citizen:logged',
  'parent:user_not_found': 'parent:proxy_select',
  'relative:user_not_found': 'relative:proxy_input',
}

export const SUCCESSFUL_LOGIN_STATES: LoginSteps[] = [
  'citizen:logged',
  'relative:logged',
  'parent:logged',
]

/**
 * We use different IDP HINT for different user types.
 */
export const IDP_HINT_BY_USER_TYPES: Record<UserTypes, string> = {
  citizen: KEYCLOAK_IDP_HINT,
  parent: KEYCLOAK_IDP_HINT,
  relative: KEYCLOAK_IDP_HINT_RELATIVE,
}
