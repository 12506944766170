<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { useRouter } from 'vue-router'

interface IProps {
  icon: string
  text: string
  name: string
}

const props = defineProps<IProps>()

const { name } = toRefs(props)

const router = useRouter()

const states = [
  'bg-neutral0 text-neutral700 h400',
  'hover:bg-darkneutral600A hover:text-neutral700 hover:h400',
  'active:bg-neutral200 active:text-neutral700 active:h400',
  'peer-checked:bg-neutral200 peer-checked:text-darkOcean500 peer-checked:h400',
  'focus-visible:neutral0 focus-visible:text-neutral700 focus-visible:h400',
]

const isSelected = computed(() => {
  return router.currentRoute.value.name === name.value
})
</script>

<template>
  <div
    :class="[...states, isSelected ? 'bg-neutral100 text-neutral700 h400' : '']"
    class="h400 px-20 py-16 flex flex-col items-center justify-center rounded-12 w-140 h-58 desktop:flex-row desktop:w-180 desktop:gap-2 desktop:unset-width cursor-pointer"
  >
    <fa-icon v-if="icon" class="text-xl" :icon="['far', icon]" />
    <p>{{ text }}</p>
  </div>
</template>
