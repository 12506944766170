import App from './App.vue'
import i18n from './utils/i18n'
import '@/utils/icons'
import { useI18n } from 'vue-i18n'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createRouter, createWebHistory } from 'vue-router'
import { createApp, provide, h } from 'vue'
import routes from './router/router'
import { ApolloClients } from '@vue/apollo-composable'
import apolloClient from '@/utils/apollo'
import { createPinia } from 'pinia'
import './styles/app.scss'
import { isPermittedRoute } from './utils/route'
import { useAuthStore } from './stores/AuthStore'
import { setupRequests } from './utils/request'

setupRequests()

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient.graphqlClient,
      restClient: apolloClient.restClient,
    })

    const { t } = useI18n()

    // We need to able to access the translator function via global scope.
    // Do NOT use this function if any views. It can cause errors.
    window.t = t

    // We should call the cookie consent modal after the i18n suport loaded.
    import('./utils/cookieConsent').then((module) => {
      module.default(app)
    })

    return { t }
  },

  render: () => h(App),
})

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    return { top: 0 }
  },
})

// I want to use this variable from outside of the App. Check the fetch.ts file.
window.router = router

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  // The following routes are allowed as guest
  const ACCESSIBLE_ROUTES_DURING_LOGIN = ['login', 'callback', 'overview']
  const FORBIDDEN_ROUTES_AFTER_LOGIN = ['login', 'callback']

  const publicRoutes = ['help', 'privacy']

  if (publicRoutes.includes(to.name?.toString() || '')) {
    next()
  } else if (to.name === 'overview' && authStore.auth.loginStep === 'none') {
    // If the login process is not started yer, we don't show the dashboard
    next({ name: 'login' })
  } else if (
    authStore.isCompleted() === false &&
    ACCESSIBLE_ROUTES_DURING_LOGIN.includes(to.name as string) === false
  ) {
    // Redirecting to the login page if the user is not logged in
    next({ name: 'login' })
  } else if (
    authStore.isCompleted() &&
    FORBIDDEN_ROUTES_AFTER_LOGIN.includes(to.name as string)
  ) {
    // Redirecting to the overview if the user already logged in but trying to
    // access to the login/callback views
    next({ name: 'overview' })
  } else {
    // Everything is fine!
    next()
  }
})

// Checking the route permission
router.beforeEach(async (to) => {
  // We should find the original route definition
  const route = routes.find((item) => item.name === to.name)
  if (!route) {
    return true
  }

  // If there is not any permission key, which means we don't need to check it
  if (!route.permission) {
    return true
  }

  // Validating the permission
  return await isPermittedRoute(route)
})

router.afterEach((to, from) => {
  // We shouldn't reset the tab order when the only active tab change in a view.
  if (to.name === from.name) {
    return
  }

  // But otherwise, we should reset the tab order. We want to redirect the user
  // to "Skip the content" button first. Check <SkipToContent /> component.
  const app = document.getElementById('app')
  if (app) {
    app.focus()
  }
})

app.component('FaIcon', FontAwesomeIcon)

app.use(i18n)
app.use(router)
app.use(createPinia())

app.mount('#app')
